const getS3ImageUrl = () => {
    if (window.location.hostname.includes('dev.' ) || window.location.hostname.includes('devx.') || window.location.hostname.includes('localhost')) {
        return 'https://d25xala63lqsma.cloudfront.net';
    } else if (window.location.hostname.includes('int.') || window.location.hostname.includes('intx.')) {
        return 'https://d2n1577gxmmma.cloudfront.net';
    } else {
        return 'https://thumbs.intersoftcdn.com';
    }
};

export default getS3ImageUrl;
