import variables from '../../../../styles/themes/theme/variables.scss';

const faqLinks = {
        tr: 'https://ruleshere.help/tr/articles/frequently_asked_questions',
        en: 'https://ruleshere.help/en/articles/frequently_asked_questions',
    },
    betBuilderTermsLinks = {
        tr: 'https://ruleshere.help/tr/bet_builder',
        en: 'https://ruleshere.help/en/bet_builder',
    },
    rulesLinks = {
        tr: 'https://ruleshere.help/tr/articles/general_rules',
        en: 'https://ruleshere.help/en/articles/general_rules',
    };

const config = {
    faqLinks: faqLinks,
    rulesLinks: rulesLinks,
    themeCSSVars: variables,
    siteContrastColor: 'black',
    siteTheme: 'light',
    couponPrint: false,
    withGoogleAnalytics: true,
    pagcorLink: "https://verification.pagcor-regulatory.ph/validate/page?domain=betlima99.com",
    pagcorImgSrc: "https://verification.pagcor-regulatory.ph/api/validate/logo?domain=betlima99.com",
    betBuilderTermsLinks: betBuilderTermsLinks,
    siteConfig: {
        email: '%%site.email%%',
        appearanceRules: {
            isLiveChat: true,
            isSignUp: true,
            isMobileSupportButton: true,
            isSocialIcons: true,
            sportIcons: 'svg',
        },
        permissionRules: {
            isAboutUs: true,
            isPayments: true,
            isForgotPassword: true,
        },
    },
    promo: {
        promoMobileLink: null, // зачем это?
    },

    headerMobileTabs: [
        {
            name: 'Sport',
            icon: 'doruk-sports-menu-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/sport/1',
            },
        },
        {
            name: 'Live',
            icon: 'doruk-live-match-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/sport/live',
            },
        },
        {
            name: 'SLOT_BATTLE',
            icon: 'doruk-slot-battles',
            as: 'NavLink',
            disabled: '%%site.slotBattlesConfig.slotBattlesDisabled{boolean}%%',
            elementAttributes: {
                to: '/slots-battles',
            },
        },
        {
            name: 'Slots',
            icon: 'doruk-slots-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/slots',
            },
        },
        {
            name: 'Casino',
            icon: 'doruk-casino-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/casino',
            },
        },
        {
            name: 'Lobby',
            icon: 'doruk-games-lobby',
            as: 'NavLink',
            elementAttributes: {
                to: '/lobby',
            },
        },
        {
            name: 'Virtual Sports',
            icon: 'doruk-virtual-games-01',
            gameId: 'virtual',
            elementAttributes: {
                to: "?launch=virtual",
                onClick: true
            },
            elementAttributesMobileUserAgent: {
                href: "/api/virtual/play?id=vfb",
                rel: "noopener noreferrer",
                target: "_blank",
                onClick: true
            },
        },
        {
            name: 'Aviator',
            icon: 'doruk-aviator-01',
            gameId: 'aviator',
            gameType: 'slots',
            elementAttributes: {
                onClick: true,
                to: '?launch=0-350-2625'
            },
            elementAttributesMobileUserAgent: {
                href: "/api/games/play?providerId=350&gameId=1496",
                rel: "noopener noreferrer",
                target: "_blank",
                onClick: true,
            },
        },
        {
            name: 'Promo',
            icon: 'doruk-bonuses-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/promo',
            },
        },
    ],

    navigationDrawerMenu: [
        {
            label: 'Sport',
            icon: 'doruk-sports-menu-01',
            elementAttributes: {
                to: '/sport/1',
            },
        },
        {
            label: 'Live',
            icon: 'doruk-live-match-01',
            elementAttributes: {
                to: '/sport/live',
            },
        },
        {
            label: 'SLOT_BATTLE',
            icon: 'doruk-slot-battles',
            disabled: '%%site.slotBattlesConfig.slotBattlesDisabled{boolean}%%',
            elementAttributes: {
                to: '/slots-battles',
            },
        },
        {
            label: 'Slots',
            icon: 'doruk-slots-01',
            elementAttributes: {
                to: '/slots',
            },
        },
        {
            label: 'Casino',
            icon: 'doruk-casino-01',
            elementAttributes: {
                to: '/casino',
            },
        },
        {
            name: 'Lobby',
            icon: 'doruk-games-lobby',
            as: 'NavLink',
            elementAttributes: {
                to: '/lobby',
            },
        },
        {
            label: 'Virtual Sports',
            icon: 'doruk-virtual-games-01',
            gameId: 'virtual',
            elementAttributes: {
                to: "?launch=virtual",
                onClick: true
            },
            elementAttributesMobileUserAgent: {
                href: "/api/virtual/play?id=vfb",
                rel: "noopener noreferrer",
                target: "_blank",
                onClick: true
            },
        },
        {
            label: 'Aviator',
            icon: 'doruk-aviator-01',
            gameId: 'aviator',
            gameType: 'slots',
            elementAttributes: {
                onClick: true,
                to: '?launch=0-350-2625'
            },
            elementAttributesMobileUserAgent: {
                href: "/api/games/play?providerId=350&gameId=1496",
                rel: "noopener noreferrer",
                target: "_blank",
                onClick: true,
            },
        },
        {
            label: 'Promotions',
            icon: 'doruk-bonuses-01',
            elementAttributes: {
                to: '/promo',
            },
        },
    ],

    footerContentMenu: [
        {
            title: '%%site.siteName%%',
            skipTranslation: true,
            links: [
                {
                    name: 'About Us',
                    disabled: false,
                    elementAttributes: {
                        to: '/aboutus',
                    },
                },
                {
                    name: 'F.A.Q.',
                    iframe: faqLinks,
                    needLang: true,
                },
                {
                    name: 'Rules',
                    iframe: rulesLinks,
                    needLang: true,
                },
                {
                    name: 'Contact Us',
                    elementAttributes: {
                        to: '/contact-us',
                    },
                },
            ],
        },
        {
            title: 'Bettings',
            links: [
                {
                    name: 'Sport Betting',
                    elementAttributes: {
                        to: '/sport/1',
                    },
                },
                {
                    name: 'Live Betting',
                    elementAttributes: {
                        to: '/sport/live',
                    },
                },
                {
                    name: 'Virtual Sports',
                    elementAttributes: {
                        to: '?launch=virtual',
                    },
                },
            ],
        },
        {
            title: 'Casino',
            links: [
                {
                    name: 'Live Casino',
                    elementAttributes: {
                        to: '/casino',
                    },
                },
                {
                    name: 'Live Bingo',
                    elementAttributes: {
                        to: '/live-games',
                        onClick: true,
                    },
                },
                {
                    name: 'Slots',
                    elementAttributes: {
                        to: '/slots',
                    },
                },
            ],
        },
        {
            title: 'Shortcuts',
            links: [
                {
                    name: 'Bonus',
                    elementAttributes: {
                        to: '/promo',
                    },
                },
            ],
        },
    ],

    footerMobileMenu: [
        {
            label: 'Sign in',
            icon: 'doruk-my-accont-01',
            isLogged: false,
            drawer: {
                key: 'login-form-drawer',
                side: 'left',
            },
        },
        {
            label: 'Sign up',
            icon: 'doruk-login-01',
            isLogged: false,
            accent: true,
            drawer: {
                key: 'signup-form-drawer',
                side: 'left',
            },
        },
        {
            label: 'Money',
            icon: 'doruk-money-01',
            isLogged: true,
            as: 'NavLink',
            elementAttributes: {
                to: '/account/withdraws',
            },
        },
        {
            label: 'Deposit Money',
            icon: 'doruk-deposit-01',
            isLogged: true,
            hideForBlocked: true,
            as: 'NavLink',
            elementAttributes: {
                to: '/account/deposit',
            },
        },
        {
            label: 'Support',
            icon: 'doruk-support-01',
            drawer: {
                key: 'support-drawer',
                side: 'right',
            },
        },
        {
            label: 'My account',
            icon: 'doruk-my-accont-01',
            isLogged: true,
            drawer: {
                key: 'my-account-menu-drawer',
                side: 'left',
            },
        },
    ],

    footerDesktop: {
        leftPart: [
            {
                id: 'popular-games',
                label: 'Popular Games',
                icon: 'llg-empire',
                elementAttributes: {
                    to: '/casino',
                },
            },
        ],
        centerPart: [
            {
                elementAttributes: {
                    to: '/',
                },
            },
        ],
        rightPart: [
            {
                label: 'Help',
                icon: 'doruk-questions',
                iframe: faqLinks,
                needLang: true,
            },
            {
                id: 'liveChat',
                label: 'Live Support',
                icon: 'doruk-live-support-01',
            },
        ],
    },

    loginDrawerMenu: [
        {
            label: 'Help',
            icon: 'doruk-questions',
        },
        {
            label: 'Live Support',
            icon: 'doruk-live-support-01',
        },
    ],

    headerDesktopTabs: [
        {
            name: 'Sport',
            icon: 'doruk-sports-menu-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/sport/1',
            },
            key: 'prematchPage',
        },
        {
            name: 'Live',
            icon: 'doruk-live-match-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/sport/live',
            },
        },
        {
            name: 'SLOT_BATTLE',
            icon: 'doruk-slot-battles',
            as: 'NavLink',
            disabled: '%%site.slotBattlesConfig.slotBattlesDisabled{boolean}%%',
            elementAttributes: {
                to: '/slots-battles',
            },
        },
        {
            name: 'Slots',
            icon: 'doruk-slots-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/slots',
            },
        },
        {
            name: 'Casino',
            icon: 'doruk-casino-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/casino',
            },
        },
        {
            name: 'Lobby',
            icon: 'doruk-games-lobby',
            as: 'NavLink',
            elementAttributes: {
                to: '/lobby',
            },
        },
        {
            name: 'Virtual Sports',
            icon: 'doruk-virtual-games-01',
            elementAttributes: {
                to: '?launch=virtual',
            },
        },
        {
            name: 'Aviator',
            icon: 'doruk-aviator-01',
            gameType: 'slots',
            gameId: '2625',
            providerId: '350',
            elementAttributes: {
                onClick: true,
                to: '?launch=0-350-2625',
            },
        },
        {
            name: 'Promotions',
            icon: 'doruk-bonuses-01',
            as: 'NavLink',
            elementAttributes: {
                to: '/promo',
            },
        },
    ],

    slotsCasinoMenu: [
        {
            name: 'Featured',
            type: 'featured',
            as: 'NavLink',
            elementAttributes: {
                to: '/featured',
            },
            icon: 'doruk-filter-featured-01',
        },
        {
            name: 'New',
            type: 'new',
            as: 'NavLink',
            elementAttributes: {
                to: '/new',
            },
            icon: 'doruk-filter-new-01',
        },
        {
            name: 'Popular',
            type: 'popular',
            as: 'NavLink',
            elementAttributes: {
                to: '/popular',
            },
            icon: 'doruk-filter-popular-01',
        },
        {
            name: 'Game Type',
            type: 'gameType',
            icon: 'doruk-filter-game-type-01',
            submenu: 'GameType',
        },
        {
            name: 'Provider',
            type: 'provider',
            icon: 'doruk-filter-provider-01',
            submenu: 'Provider',
        },
        {
            name: 'Jackpots',
            type: 'jackpots',
            icon: 'doruk-icon-jackpot',
            as: 'NavLink',
            elementAttributes: {
                to: '/jackpots',
            },
        },
        {
            name: 'Favorite',
            type: 'favorite',
            as: 'NavLink',
            elementAttributes: {
                to: '/favorite',
            },
            icon: 'doruk-favorites',
        },
        {
            name: 'Recently played',
            type: 'recently-played',
            as: 'NavLink',
            elementAttributes: {
                to: '/recently-played',
            },
            icon: 'doruk-recently-played',
        },
    ],

    slotsCasinoMobileMenu: [
        {
            name: 'Featured',
            type: 'featured',
            as: 'NavLink',
            elementAttributes: {
                to: '/featured',
            },
            icon: 'doruk-filter-featured-01',
        },
        {
            name: 'New',
            type: 'new',
            as: 'NavLink',
            elementAttributes: {
                to: '/new',
            },
            icon: 'doruk-filter-new-01',
        },
        {
            name: 'Popular',
            type: 'popular',
            as: 'NavLink',
            elementAttributes: {
                to: '/popular',
            },
            icon: 'doruk-filter-popular-01',
        },
        {
            name: 'Sort by',
            type: 'sortBy',
            position: {
                upper: true,
            },
            icon: 'doruk-filter-sort-by-01',
            submenu: [
                {
                    name: 'A - Z',
                    sort: [
                        { selector: 'GameName', desc: false },
                        { selector: 'SortOrder', desc: false },
                    ],
                    icon: 'llg-long-arrow-down',
                },
                {
                    name: 'Z - A',
                    sort: [
                        { selector: 'GameName', desc: true },
                        { selector: 'SortOrder', desc: false },
                    ],
                    icon: 'llg-long-arrow-up',
                },
                {
                    name: 'Oldest',
                    sort: [
                        { selector: 'Created', desc: false },
                        { selector: 'SortOrder', desc: false },
                        { selector: 'GameName', desc: false },
                    ],
                    icon: 'llg-long-arrow-down',
                },
                {
                    name: 'Newest',
                    sort: [
                        { selector: 'Created', desc: true },
                        { selector: 'SortOrder', desc: false },
                        { selector: 'GameName', desc: false },
                    ],
                    icon: 'llg-long-arrow-up',
                },
                {
                    name: 'Popular',
                    sort: [
                        {selector: 'LaunchCnt', desc: true},
                        {selector: 'SortOrder', desc: false},
                        {selector: 'GameName', desc: false},
                    ],
                    icon: 'doruk-sort-by-popular',
                },
            ],
        },
        {
            name: 'Game Type',
            type: 'gameType',
            icon: 'doruk-filter-game-type-01',
            submenu: 'GameType',
        },
        {
            name: 'Provider',
            type: 'provider',
            icon: 'doruk-filter-provider-01',
            submenu: 'Provider',
        },
        {
            name: 'Jackpots',
            type: 'jackpots',
            icon: 'doruk-icon-jackpot',
            as: 'NavLink',
            elementAttributes: {
                to: '/jackpots',
            },
        },
        {
            name: 'Favorite',
            type: 'favorite',
            as: 'NavLink',
            elementAttributes: {
                to: '/favorite',
            },
            icon: 'doruk-favorites',
        },
        {
            name: 'Recently played',
            type: 'recently-played',
            as: 'NavLink',
            elementAttributes: {
                to: '/recently-played',
            },
            icon: 'doruk-recently-played',
        },
    ],

    slotsCasinoUpperMenu: [
        {
            type: 'playMode',
            position: {
                mobileOnly: true,
                upper: true,
                start: true,
            },
            icon: 'llg-poker-chip',
            submenu: [
                {
                    name: 'REAL_PLAY_BTN',
                    demo: 0,
                    icon: 'llg-money',
                },
                {
                    name: 'FUN_PLAY_BTN',
                    demo: 1,
                    icon: 'llg-star-filled',
                },
            ],
        },
        {
            name: 'Sort by',
            type: 'sortBy',
            position: {
                upper: true,
            },
            icon: 'doruk-filter-sort-by-01',
            submenu: [
                {
                    name: 'A - Z',
                    sort: [
                        { selector: 'GameName', desc: false },
                        { selector: 'SortOrder', desc: false },
                    ],
                    icon: 'llg-long-arrow-down',
                },
                {
                    name: 'Z - A',
                    sort: [
                        { selector: 'GameName', desc: true },
                        { selector: 'SortOrder', desc: false },
                    ],
                    icon: 'llg-long-arrow-up',
                },
                {
                    name: 'Oldest',
                    sort: [
                        { selector: 'Created', desc: false },
                        { selector: 'SortOrder', desc: false },
                        { selector: 'GameName', desc: false },
                    ],
                    icon: 'llg-long-arrow-down',
                },
                {
                    name: 'Newest',
                    sort: [
                        { selector: 'Created', desc: true },
                        { selector: 'SortOrder', desc: false },
                        { selector: 'GameName', desc: false },
                    ],
                    icon: 'llg-long-arrow-up',
                },
                {
                    name: 'Popular',
                    sort: [
                        {selector: 'LaunchCnt', desc: true},
                        {selector: 'SortOrder', desc: false},
                        {selector: 'GameName', desc: false},
                    ],
                    icon: 'doruk-sort-by-popular',
                },
            ],
        },
    ],

    slotsCasinoMobileUpperMenu: [
        {
            type: 'playMode',
            position: {
                mobileOnly: true,
                upper: true,
                start: true,
            },
            icon: 'llg-poker-chip',
            submenu: [
                {
                    name: 'REAL_PLAY_BTN',
                    demo: 0,
                    icon: 'llg-money',
                },
                {
                    name: 'FUN_PLAY_BTN',
                    demo: 1,
                    icon: 'llg-star-filled',
                },
            ],
        },
        {
            type: 'filters',
            name: 'Filters',
            position: {
                mobileOnly: true,
                upper: true,
            },
            icon: 'doruk-filters',
            submenu: 'slotsCasinoMobileMenu',
        },
    ],

    footerLicenceIcons: [
        {
            icon: 'll-license-icons--license',
            sealId: 'bf976a6e-6aab-48bf-a794-4066eac72bb5',
        },
        {
            modalLink: rulesLinks,
            icon: 'll-license-icons--18',
            customClasses: 'luckyland-link',
            needLang: true,
        },
        {
            icon: 'll-license-icons--circle',
            elementAttributes: {
                to: '/contact-us',
            },
        },
        // {
        //     modalLink: 'https://www.luckylandgaming.com/',
        //     icon: 'll-license-icons--logo',
        // },
    ],

    mobileLicenceIcons: [
        {
            elementAttributes: {
                href: rulesLinks,
                target: '_blank',
                rel: 'noopener noreferrer',
            },
            icon: 'll-license-icons--18',
            needLang: true,
        },
        {
            icon: 'll-license-icons--license',
            sealId: 'bf976a6e-6aab-48bf-a794-4066eac72bb5',
        },
    ],

    /*
     * name - string,
     * disabled - boolean  (hide button),
     * id - string
     * iframe - string (url for iframe)
     * icon - string
     * to - string (url for external link)
     */
    mobileSupportMenu: [
        {
            name: 'Live support',
            id: 'liveChat',
            disabled: false,
            icon: 'doruk-live-support-01',
        },
        {
            name: 'Rules',
            icon: 'doruk-rules-01',
            iframe: rulesLinks,
            needLang: true,
        },
        {
            name: 'About Us',
            icon: 'doruk-about-us-01',
            disabled: false,
            elementAttributes: {
                to: '/aboutus',
            },
        },
        {
            name: 'Contact Us',
            icon: 'doruk-support-01',
            disabled: false,
            elementAttributes: {
                to: '/contact-us',
            },
        },
        {
            name: 'Frequently asked questions',
            iframe: faqLinks,
            icon: 'doruk-questions',
            needLang: true,
        },
    ],

    myAccountDesktopMenu: [
        {
            name: 'my-info',
            icon: 'ma-my-information-01',
            link: 'my-info',
            visibleForBlockedUser: true,
        },
        {
            name: 'deposits',
            icon: 'ma-deposit-01',
            link: 'deposit',
        },
        {
            name: 'withdraws',
            icon: 'ma-withdraw-01',
            link: 'withdraws',
        },
        {
            name: 'withdraw-status',
            icon: 'ma-withdraw-status-01',
            link: 'withdraw-status',
        },
        {
            name: 'betting-history',
            icon: 'ma-betting-history-01',
            link: 'betting-history',
            visibleForBlockedUser: true,
        },
        {
            name: 'account-history',
            icon: 'ma-account-history-01',
            link: 'account-history',
            visibleForBlockedUser: true,
        },
        {
            name: 'casino-report',
            icon: 'ma-casino-report-01',
            link: 'casino-report',
            visibleForBlockedUser: true,
        },
        {
            name: 'limits',
            icon: 'ma-my-limits-01',
            link: 'limits',
            visibleForBlockedUser: true,
        },
        {
            name: 'google-auth',
            icon: 'ma-google-authenticator-01',
            link: 'google-auth',
            visibleForBlockedUser: true,
        },
        {
            name: 'messages',
            icon: 'ma-mssages-01',
            link: 'messages',
            visibleForBlockedUser: true,
        },
    ],
    /*
     * name - string,
     * storeLink - string (show value from store),
     * disabled - boolean  (hide button),
     * path - string
     * visibleForBlockedUser - boolean,
     * hideForDesktop - boolean
     * image - string (svg name for the menu item in Desktop menu)
     * storeCurrencyLink - boolean (show data with two digits after comma)
     */
    myAccountMenu: [
        {
            name: 'my-info',
            elementAttributes: {
                to: '/account/my-info',
            },
            icon: 'ma-my-information-01',
            visibleForBlockedUser: true,
        },
        {
            name: 'deposits',
            elementAttributes: {
                to: '/account/deposit',
            },
            icon: 'ma-deposit-01',
        },
        {
            name: 'withdraws',
            elementAttributes: {
                to: '/account/withdraws',
            },
            icon: 'ma-withdraw-01',
        },
        {
            name: 'withdraw-status',
            elementAttributes: {
                to: '/account/withdraw-status',
            },
            icon: 'ma-withdraw-status-01',
        },
        {
            name: 'account-history',
            elementAttributes: {
                to: '/account/account-history',
            },
            icon: 'ma-betting-history-01',
            visibleForBlockedUser: true,
        },
        {
            name: 'betting-history',
            drawer: {
                key: 'my-bets-drawer',
                side: 'right',
            },
            icon: 'ma-account-history-01',
            visibleForBlockedUser: true,
        },
        {
            name: 'casino-report',
            elementAttributes: {
                to: '/account/casino-report',
            },
            icon: 'ma-casino-report-01',
            visibleForBlockedUser: true,
        },
        {
            name: 'limits',
            elementAttributes: {
                to: '/account/limits',
            },
            icon: 'ma-my-limits-01',
            visibleForBlockedUser: true,
        },
        {
            name: 'google-auth',
            elementAttributes: {
                to: '/account/google-auth',
            },
            icon: 'ma-google-authenticator-01',
            visibleForBlockedUser: false,
        },
        {
            name: 'messages',
            elementAttributes: { to: '/account/messages' },
            icon: 'ma-mssages-01',
            storeLink: 'messageCount',
            visibleForBlockedUser: true,
            hideForDesktop: true,
        },
    ],

    // * disabled - boolean  (hide icon),

    socialIcons: [
        {
            elementAttributes: {
                href: 'https://mail.google.com/',
                target: '_blank',
                rel: 'noopener noreferrer',
            },
            icon: 'llg-envelope-o',
        },
        {
            elementAttributes: {
                href: 'https://www.facebook.com/',
                target: '_blank',
                rel: 'noopener noreferrer',
            },
            icon: 'llg-facebook',
        },
        {
            elementAttributes: {
                href: 'https://web.telegram.org/',
                target: '_blank',
                rel: 'noopener noreferrer',
            },
            icon: 'llg-paper-plane',
        },
        {
            elementAttributes: {
                href: 'https://www.youtube.com/',
                target: '_blank',
                rel: 'noopener noreferrer',
            },
            icon: 'llg-youtube',
        },
    ],

    /**** ~~ MY ACCOUNT  ****/
    payments: {
        deposit: {
            mobileTitle: 'Deposit options',
            desktopTitle: 'Deposit options',
            noDataMessage: 'There is no available deposit methods for your account.',
            name: 'deposits',
            getDataFunctionName: 'getDeposits',
        },
        withdraws: {
            mobileTitle: 'Withdraw options',
            desktopTitle: 'Withdraw options',
            noDataMessage: 'There is no available withdraw methods for your account.',
            name: 'withdraws',
            getDataFunctionName: 'getWithdraws',
        },
    },

    accountReport: {
        sports: {
            desktopTitle: 'Sports bets',
            mobileTitle: 'Sports bets',
            isLabelsCapitalized: true,
            items: [
                {
                    label: 'Stake total',
                    value: '%%user.accountReport.sports.stake{number}%%',
                },
                {
                    label: 'Paid total',
                    value: '%%user.accountReport.sports.paid{number}%%',
                },
                { label: 'Net', value: '%%user.accountReport.sports.net{number}%%' },
            ],
        },
        casino: {
            desktopTitle: 'Casino',
            mobileTitle: 'Casino',
            items: '%%user.accountReport.casinoReports{array}%%',
        },
        bonus: {
            desktopTitle: 'Bonus',
            mobileTitle: 'Bonus/discount',
            isLabelsCapitalized: true,
            items: [
                { label: 'Bonus', value: '%%user.accountReport.bonus.bonus{number}%%' },
                {
                    label: 'Commission',
                    value: '%%user.accountReport.bonus.commission{number}%%',
                },
                { label: 'Net', value: '%%user.accountReport.bonus.net{number}%%' },
            ],
        },
    },

    // scripts: { //Retrobet scripts example // нужно только для Ретро и другихсайтов, где есть сторонние скрипты заказчика
    //     signupScript: "<script>window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);" +
    //                     "window._adftrack.push({" +
    //                     "pm: 1923849," +
    //                     "divider: encodeURIComponent('|')," +
    //                     "pagename: encodeURIComponent('Retrobet_Registeration')" +
    //                     "});" +
    //                     "(function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://track.adform.net/serving/scripts/trackpoint/async/'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();" +
    //                     "</script>",
    //     headerScript: "<script>window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);" +
    //                     "window._adftrack.push({" +
    //                     "pm: 1923849," +
    //                     "divider: encodeURIComponent('|')," +
    //                     "pagename: encodeURIComponent('Retrobet_Remarketing')" +
    //                     "});" +
    //                     "(function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://track.adform.net/serving/scripts/trackpoint/async/'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();" +
    //                     "</script>",
    // },
};

export default config;
